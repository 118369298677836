// src/pages/AboutPage.js

import React from 'react';
import Layout from '../components/Layout';

const AboutPage = () => {
  return (
    <Layout>
      <div className="container mx-auto px-4 pb-8 -mt-6">
        <div className="max-w-5xl mx-auto flex flex-col md:flex-row items-start">
          <div className="md:w-1/3 mb-6 md:mb-0 md:mr-8">
            <img 
              src="luanneww.jpeg" 
              alt="Designer Portrait" 
              className="w-full rounded-lg shadow-lg"
            />
          </div>
          <div className="md:w-2/3 max-w-xl font-['Source_Code_Pro'] text-gray-700 text-base"> {/* Changed text-lg to text-base */}
            <p className="mb-2">
              I'm a graduate of Kendall College of Art and Design, mother of three, self-professed coffee lover, and lifelong design junkie. I have experience in both commercial and residential design.
            </p>

            <p className="mb-4">
              I love composition, color, texture, geometry, and the sense of peace that comes from spending time in a space that intentionally combines all these elements together.
            </p>

            <p className="mb-4">
              Whether it's architecture, fashion, interiors, or visual art, I'm fascinated by the elements that create beautiful aesthetics.
            </p>

            <p className="mb-4">
              But beyond that, the psychology behind the design is what drives me. Not just what makes up a beautiful design, but why we perceive it as such.
            </p>

            <p className="mb-4">
              So let's chat - I'd love to work together, help find your style, and turn your space into something exceptional.
            </p>

            <p className="mb-4">
              <a href="mailto:luanne@whitandwillow.com">luanne@whitandwillow.com</a>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;