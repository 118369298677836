import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Instagram } from 'lucide-react';


const Navigation = () => {
  const [portfolioCategories, setPortfolioCategories] = useState([]);
  const [showPortfolioSubmenu, setShowPortfolioSubmenu] = useState(false);
  const [error, setError] = useState(null);
  const timeoutRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('/api/portfolio-categories');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Received categories:', data);
        setPortfolioCategories(data);
      } catch (error) {
        console.error('Error fetching portfolio categories:', error);
        setError('Failed to load categories. Please try again later.');
      }
    };

    fetchCategories();
  }, []);

  const handleMouseEnter = () => {
    clearTimeout(timeoutRef.current);
    setShowPortfolioSubmenu(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setShowPortfolioSubmenu(false);
    }, 300); // 300ms delay before hiding submenu
  };

  const isActive = (path) => {
    if (path === '/') {
      return location.pathname === '/';
    }
    return location.pathname.startsWith(path);
  };

  return (
    <nav className="w-full">
      <div className="border-t border-gray-200 w-full mb-4"></div>
      <ul className="flex justify-center space-x-12 text-gray-400 text-sm font-['Source_Code_Pro']">
        <li>
          <Link 
            to="/" 
            className={`hover:text-gray-600 transition-colors duration-300 ${isActive('/') ? 'text-gray-600 border-b-2 border-gray-600' : ''}`}
          >
            Home
          </Link>
        </li>
        <li 
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="relative group"
        >
          <span className={`hover:text-gray-600 transition-colors duration-300 cursor-default ${isActive('/portfolio') ? 'text-gray-600 border-b-2 border-gray-600' : ''}`}>Portfolio</span>
          {portfolioCategories.length > 0 && (
            <ul className={`absolute left-1/2 transform -translate-x-1/2 mt-2 flex space-x-6 bg-white p-3 shadow-md transition-opacity duration-300 ${showPortfolioSubmenu ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
              {portfolioCategories.map((category) => (
                <li key={category}>
                  <Link 
                    to={`/portfolio/${category}`}
                    className="hover:text-gray-600 transition-colors duration-300 whitespace-nowrap block"
                  >
                    {category}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </li>
        <li>
          <Link 
            to="/about" 
            className={`hover:text-gray-600 transition-colors duration-300 ${isActive('/about') ? 'text-gray-600 border-b-2 border-gray-600' : ''}`}
          >
            About
          </Link>
        </li>
        <li>
          <a href="https://instagram.com/whit_and_willow" target="_blank" rel="noopener noreferrer" className="hover:text-gray-600 transition-colors duration-300">
            <Instagram size={20} />
          </a>
        </li>
      </ul>
      <div className="border-b border-gray-200 w-full mt-4 mb-6"></div>
      {error && <div className="text-red-500 text-center">{error}</div>}
    </nav>
  );
};

export default Navigation;