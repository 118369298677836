import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import PortfolioPage from './pages/PortfolioPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';

const App = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <div className="container mx-auto px-4 flex-grow">
          <header className="py-8 flex flex-col items-center">
            <div className="mb-6">
              <img 
                src="/logo.png" 
                alt="Logo" 
                className="h-48 w-auto"
              />
            </div>
            <Navigation />
          </header>
          
          <main>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/portfolio/:category?" element={<PortfolioPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/contact" element={<ContactPage />} />
            </Routes>
          </main>
        </div>
        
        <Footer />
      </div>
    </Router>
  );
};

export default App;