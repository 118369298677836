import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Masonry from 'react-masonry-css';
import useImageLoad from '../hooks/useImageLoad';

const ImageWithFade = ({ src, alt, index, category }) => {
  const loaded = useImageLoad(src);
  return (
    <Link to={`/portfolio/${category}`} className="block">
      <img 
        src={src} 
        alt={alt} 
        className={`w-full h-auto transition-opacity duration-500 ${loaded ? 'opacity-100' : 'opacity-0'}`}
        style={{ transitionDelay: `${index * 50}ms` }}
      />
    </Link>
  );
};

const HomePage = () => {
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        setIsLoading(true);
        const response = await fetch('/api/random-portfolio-images');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setImages(data);
      } catch (error) {
        console.error('Error fetching random images:', error);
        setError('Failed to load images. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchImages();
  }, []);

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1
  };

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 -mt-6"> {/* Added -mt-6 here */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="flex w-auto -ml-4"
          columnClassName="pl-4 bg-clip-padding"
        >
          {images.map((image, index) => (
            <div key={index} className="mb-4">
              <ImageWithFade 
                src={`/portfolio/${image.category}/${image.image}`} 
                alt={`${image.category} - ${image.image}`}
                index={index}
                category={image.category}
              />
            </div>
          ))}
        </Masonry>
      )}
    </div>
  );
};

export default HomePage;