import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Masonry from 'react-masonry-css';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import useImageLoad from '../hooks/useImageLoad';

const ImageWithFade = ({ src, alt, index, onClick }) => {
  const loaded = useImageLoad(src);
  return (
    <img 
      src={src} 
      alt={alt} 
      className={`w-full h-auto transition-opacity duration-500 cursor-pointer ${loaded ? 'opacity-100' : 'opacity-0'}`}
      style={{ transitionDelay: `${index * 100}ms` }}
      onClick={onClick}
    />
  );
};

const PortfolioPage = () => {
  const { category } = useParams();
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        let url = '/api/random-portfolio-images';
        if (category) {
          url = `/api/portfolio-images/${category}`;
        }
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setImages(data);
      } catch (error) {
        console.error('Error fetching images:', error);
        setError('Failed to load images. Please try again later.');
      }
    };

    fetchImages();
  }, [category]);

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1
  };

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setLightboxIsOpen(true);
  };

  const lightboxImages = images.map(image => ({
    src: `/portfolio/${category || image.category}/${image.image}`,
    alt: `${category || image.category} - ${image.image}`
  }));

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4">
      <h1 className="text-3xl font-bold mb-6 text-center font-['Source_Code_Pro'] text-gray-700">
      {category}
      </h1>
      {images.length > 0 ? (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="flex w-auto -ml-4"
          columnClassName="pl-4 bg-clip-padding"
        >
          {images.map((image, index) => (
            <div key={index} className="mb-4">
              <ImageWithFade 
                src={`/portfolio/${category || image.category}/${image.image}`} 
                alt={`${category || image.category} - ${image.image}`}
                index={index}
                onClick={() => openLightbox(index)}
              />
            </div>
          ))}
        </Masonry>
      ) : (
        <p className="text-center">Loading images...</p>
      )}

      <Lightbox
        open={lightboxIsOpen}
        close={() => setLightboxIsOpen(false)}
        index={photoIndex}
        slides={lightboxImages}
      />
    </div>
  );
};

export default PortfolioPage;