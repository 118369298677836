import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="mt-auto py-4">
      <div className="container mx-auto px-4">
        <div className="border-t border-gray-200 pt-4 mt-4">
          <p className="text-center text-sm text-gray-500 font-['Source_Code_Pro']">
            © Whit and Willow {currentYear}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;